import { ref } from 'vue';

import { ExtensionPoint } from '@mfl/framework';

import { HeaderMode, type HeaderComponent } from './api.types.js';
import { shouldShowLogo, customLogoUrl } from './header-extentions.js';
export { type HeaderComponent, HeaderMode } from './api.types.js';

/**
 * Dynamically inject components to the header (left side)
 */
export const headerComponents = new ExtensionPoint<HeaderComponent>();

/**
 * Dynamically inject components to the right side of the header
 */
export const headerRightComponents = new ExtensionPoint<HeaderComponent>();

/**
 * Announcement components (above the header)
 */
export const headerAnnouncementComponents =
  new ExtensionPoint<HeaderComponent>();

/**
 * Hide/Show the logo\
 * Usually used when going into "drilldown mode" - settings and editor views
 */
export function showLogo(show: boolean) {
  shouldShowLogo.value = show;
}

/**
 * Set a custom logo URL for the header, replace WiseStamp logo
 * @param url URL of the custom logo
 */
export function setCustomLogo(url: string) {
  customLogoUrl.value = url;
}

/**
 * Display state of the header
 *
 * Can be set to show/hide the whole thing\
 * (announcements included)
 *
 * Hide the header when product asks for no header or a fully custom header\
 * For other use-cases, consider hiding the logo and registering a custom component into `headerComponents`\
 * (left side section of the header)\
 * When you implement a full-width header you won't be able to use the core-navigation either.. so hide it also
 *
 * Don't forget to return the header to its previous state when your module/view unloads
 *
 * *Note about implementation:\
 * Not too happy with using a vue construct (`ref`)\
 * Should consider creating a `State` construct
 */
export const headerMode = ref<HeaderMode>(HeaderMode.Shown);
