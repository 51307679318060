<script setup lang="ts">
import { computed } from 'vue';
import { customLogoUrl } from '../header-extentions';

import DEFAULT_LOGO from '../assets/wisestamp-logo.svg';

const logoSrc = computed(() => customLogoUrl.value || DEFAULT_LOGO);
</script>

<template>
  <img :src="logoSrc" />
</template>

<style scoped>
img {
  max-height: 29px;
}
</style>
