<script setup lang="ts">
import { computed, shallowRef } from 'vue';

import HeaderLogo from './components/header-logo.vue';
import { HeaderComponent, HeaderMode } from './api.types';
import {
  headerMode,
  headerComponents,
  headerRightComponents,
  headerAnnouncementComponents,
} from './api';
import { shouldShowLogo } from './header-extentions.js';

const leftComponents = shallowRef<HeaderComponent[]>([]);
headerComponents.subscribe((components) => {
  console.debug('core-header: components changed', components);
  leftComponents.value = [...components];
});

const rightComponents = shallowRef<HeaderComponent[]>([]);
headerRightComponents.subscribe((components) => {
  console.debug('core-header: right components changed', components);
  rightComponents.value = [...components];
});

const announcementComponents = shallowRef<HeaderComponent[]>([]);
headerAnnouncementComponents.subscribe((components) => {
  console.debug('core-header: announcements changed', components);
  announcementComponents.value = [...components];
});

// Template uses v-show so we have no DOM changes when coming out of Hidden mode
// Also, didn't want to add wrapper div around everything, so announcements and main each have their own v-show
const isShown = computed(() => headerMode.value === HeaderMode.Shown);
</script>

<template>
  <!-- ANNOUNCEMENTS -->
  <component
    v-show="isShown"
    :is="component.component"
    v-for="component in announcementComponents"
    :key="component.key"
    v-bind="component.props || {}"
    v-on="component.events || {}"
  >
  </component>

  <div v-show="isShown" class="core-header">
    <header-logo v-if="shouldShowLogo" />

    <!-- HEADER - LEFT SIDE  -->
    <component
      :is="component.component"
      v-for="component in leftComponents"
      :key="component.key"
      v-bind="component.props || {}"
      v-on="component.events || {}"
    >
    </component>

    <div class="title"></div>

    <!-- HEADER - RIGHT SIDE  -->
    <component
      :is="component.component"
      v-for="component in rightComponents"
      :key="component.key"
      v-bind="component.props || {}"
      v-on="component.events || {}"
    >
    </component>
  </div>
</template>
<style scoped>
.core-header {
  padding: 0px 24px;
  border-bottom: 1px solid #e0e0e0;
  height: 55px;
  text-align: center;
  gap: 10px;
  flex-wrap: nowrap;
  display: flex;
  min-height: 50px;
  width: 100%;
  position: relative;
  align-items: center;

  .title {
    flex: 1 1 0%;
    min-width: 1px;
    max-width: 100%;
  }
}

.impersonation-alert {
  display: flex;
  width: 100%;
  height: 30px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  background: #ea4335;
  color: #ffffff;
}
</style>
