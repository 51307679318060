import { Component } from 'vue';

export type HeaderComponent = {
  /**
   * unique name of the component\
   * will also be used for automation\
   * Use CONSTANT_CASE
   */
  key: string;
  component: Component;
  events?: HeaderComponentEvents;
  props?: HeaderComponentProps;
};

export type HeaderComponentEvents = Record<string, (payload: unknown) => void>;
export type HeaderComponentProps = Record<string, unknown>;

export enum HeaderMode {
  Shown,
  Hidden,
}
