import { bootstrapMicrofrontend } from '@mfl/vue-common';

import mfe from './core-header-mfe.vue';
import { useQuasarPlugin } from '@mfl/common-components';

const { bootstrap, mount, unmount } = bootstrapMicrofrontend({
  name: 'core-header',
  mainComponent: mfe,
  // targetElement: '#content',
  includeCss: true,
  replaceMode: true,
  handleVueApp: (app) => {
    useQuasarPlugin(app);
  },
});

export { bootstrap, mount, unmount };

// public API
export * from './api.js';
